<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="hub_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Hub</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub, index) in hubs" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ hub.name }}</td>
              <td>
                <a-tag color="#87d068" v-if="hub.status === 1">Active</a-tag>
                <a-tag color="#f50" v-else>Inactive</a-tag>
              </td>
              <td>
                <router-link :to="{ name: 'adminHubProductStockDetail', params: { hub_id: hub.id } }" class="btn btn-sm btn-primary mr-2" tag="a">
                  <i class="fe fe-eye"/> View Stock
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hubs.length === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'hub',
  components: { Empty },
  data() {
    return {
      hubs: {},
      loading: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getHubs()
  },
  methods: {
    getHubs() {
      this.flag = true
      this.loading = true
      apiClient.get('api/hub/product-stock/list')
        .then(response => {
          this.loading = false
          this.flag = true
          this.hubs = response.data.hubs
          setTimeout(function() {
            $('#hub_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
